var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0 m-shadow",attrs:{"headers":_vm.headers,"items":_vm.datasourcesync,"flat":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.nombre_pantalla))]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" add ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-text-field',{attrs:{"label":"Limite mínimo","readonly":"","type":"number","suffix":"%"},model:{value:(_vm.editedItem.limit_min),callback:function ($$v) {_vm.$set(_vm.editedItem, "limit_min", $$v)},expression:"editedItem.limit_min"}})],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:(
                      'min_value:' +
                        _vm.editedItem.limit_min +
                        '|required|max_value:100'
                    ),expression:"\n                      'min_value:' +\n                        editedItem.limit_min +\n                        '|required|max_value:100'\n                    "}],attrs:{"label":"Limite máximo","name":"Limite máximo","type":"number","error-messages":_vm.errors.collect('Limite máximo'),"suffix":"%"},model:{value:(_vm.editedItem.limit_max),callback:function ($$v) {_vm.$set(_vm.editedItem, "limit_max", $$v)},expression:"editedItem.limit_max"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('DxColorBox',{model:{value:(_vm.editedItem.color),callback:function ($$v) {_vm.$set(_vm.editedItem, "color", $$v)},expression:"editedItem.color"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("¿Está seguro que quiere eliminar el limite?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("No")]),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Sí")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.color",fn:function(ref){
                    var item = ref.item;
return [_c('div',{style:('height: 15px;width: 15px; background-color:' +
          item.color +
          '; border-radius: 50%;')})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{attrs:{"color":"red","icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}},{key:"no-data",fn:function(){return [_vm._v(" No hay datos de limites para este cuadro de mando ")]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }